import { ProductName, Promo } from '@entities/enums';
import { PromoConfig, PromoName } from '@entities/types';
import storeService from '@services/StoreService';
import {
  energyRefixFreeAbsPromoCodes,
  q1HalfPriceAbsPromoCodes,
  q2HalfPriceAbsPromoCodes,
} from '@constants/promotions';
import PromoService from '@services/PromoService';
import {
  beyondPromoFootnotes,
  energyRefixFreeAbsFootnote,
  q2HalfPriceAbsFootnotes,
  q3FreeMonthsInsuranceFootnotes,
} from '@constants/footnotes';
import { generateSequentialPromocode } from '@utils/generateSequentialPromocode';
import StoreService from '@services/StoreService';

export const indirectPromos: PromoConfig[] = [
  {
    order: 2,
    name: PromoName.HeadlineOfferThreeMonthsFree,
    appliesTo: [
      ProductName.STARTER,
      ProductName.ESSENTIALS,
      ProductName.COMPLETE,
    ],
    startDate: new Date('2024-09-17T00:00Z'),
    endDate: new Date('2024-12-12T23:59:59'),
    footnote: q3FreeMonthsInsuranceFootnotes.q3FreeMonthsInsurance,
    promoOptions: {
      type: 'FIRSTMONTHSFREE',
      duration: 3,
    },
    validate: () => {
      const { isBeyond } = StoreService.load();

      return !isBeyond;
    },
  },
  {
    order: 5,
    name: PromoName.HomeRecoverFreeRepair,
    appliesTo: [ProductName.COMPLETE],
    addon: true,
    startDate: new Date('2024-09-17T00:00Z'),
    endDate: new Date('2024-12-12T23:59:59'),
    promoOptions: {
      type: 'FREEREPAIR',
    },
    validate: () => {
      const store = storeService.load();
      return store.isHomeRecover === true && store.tariffName === undefined;
    },
  },
  {
    order: 1,
    name: PromoName.FlashSaleSixMonthsFree,
    appliesTo: [
      ProductName.STARTER,
      ProductName.ESSENTIALS,
      ProductName.COMPLETE,
    ],
    startDate: new Date('2024-10-01T00:00Z'),
    endDate: new Date('2024-10-17T23:59:00'),
    footnote: q3FreeMonthsInsuranceFootnotes.q3FreeMonthsInsuranceFlashSale,
    promoOptions: {
      type: 'FIRSTMONTHSFREE',
      duration: 6,
    },
    validate: () => {
      const { isBeyond } = StoreService.load();

      return !isBeyond;
    },
  },
  {
    order: 1,
    name: PromoName.BlackFridaySixMonthsFree,
    appliesTo: [
      ProductName.STARTER,
      ProductName.ESSENTIALS,
      ProductName.COMPLETE,
    ],
    startDate: new Date('2024-11-19T00:00Z'),
    endDate: new Date('2024-12-05T23:59:00'),
    promoOptions: {
      type: 'FIRSTMONTHSFREE',
      duration: 6,
    },
    validate: () => {
      const { isBeyond } = StoreService.load();

      return !isBeyond;
    },
  },
  {
    order: 3,
    validate: () => {
      const store = storeService.load();
      return Boolean(
        !store.isHomeRecover &&
          !store.isBeyond &&
          store.isCreditEligible &&
          store.creditType === 'SVT'
      );
    },
    name: PromoName.EnergyCreditSVT,
    addon: true,
    appliesTo: [
      ProductName.STARTER,
      ProductName.ESSENTIALS,
      ProductName.COMPLETE,
    ],
    endDate: new Date('2024-09-17T00:00Z'),
    promoOptions: {
      type: 'ENERGYCREDIT',
      amounts: {
        [ProductName.ESSENTIALS]: 50,
        [ProductName.STARTER]: 50,
        [ProductName.COMPLETE]: 50,
      },
    },
  },
  {
    order: 3,
    validate: () => {
      const store = storeService.load();
      return Boolean(
        !store.isHomeRecover &&
          !store.isBeyond &&
          store.isCreditEligible &&
          store.creditType === 'FIXED'
      );
    },
    startDate: new Date('2024-02-28T09:00Z'),
    endDate: new Date('2024-09-17T00:00Z'),
    name: PromoName.EnergyCreditFixed,
    addon: true,
    appliesTo: [
      ProductName.STARTER,
      ProductName.ESSENTIALS,
      ProductName.COMPLETE,
    ],
    promoOptions: {
      type: 'ENERGYCREDIT',
      amounts: {
        [ProductName.ESSENTIALS]: 100,
        [ProductName.STARTER]: 100,
        [ProductName.COMPLETE]: 100,
      },
    },
  },
  {
    order: 100,
    name: PromoName.Q2AbsOffer,
    startDate: new Date('2024-04-02T00:00Z'),
    endDate: new Date('2024-07-25T23:59:00.000+01:00'),

    appliesTo: [ProductName.ABS],
    footnote: q2HalfPriceAbsFootnotes?.q2HalfPriceAbs,
    promoOptions: {
      type: 'PERCENTDISCOUNT',
      percentage: 50,
    },
  },
];

const directPromos: PromoConfig[] = [
  {
    order: 1,
    validate: () => {
      const { isBeyond, isHomeRecover } = StoreService.load();
      const isFlashSale = PromoService.getActiveIndirectPromos().some(
        (promo) =>
          promo.name === PromoName.FlashSaleSixMonthsFree ||
          promo.name === PromoName.BlackFridaySixMonthsFree
      );
      return !isFlashSale && !isBeyond && !isHomeRecover;
    },
    endDate: new Date('2024-12-13T00:00:00'),
    startDate: new Date('2024-09-17T00:00Z'),
    name: PromoName.TargetedOfferFourMonthsFree,
    appliesTo: [
      ProductName.STARTER,
      ProductName.ESSENTIALS,
      ProductName.COMPLETE,
    ],
    footnote: q3FreeMonthsInsuranceFootnotes.q3FreeMonthsInsurance,
    promoOptions: {
      type: 'FIRSTMONTHSFREE',
      duration: 4,
      codes: [
        ...generateSequentialPromocode(Promo.Q4SAVE, 150),
        Promo.Q424BEYOND,
      ],
    },
  },
  {
    order: 1,
    validate: () => {
      const { isBeyond, isHomeRecover } = StoreService.load();
      const isFlashSale = PromoService.getActiveIndirectPromos().some(
        (promo) =>
          promo.name === PromoName.FlashSaleSixMonthsFree ||
          promo.name === PromoName.BlackFridaySixMonthsFree
      );
      return isFlashSale && !isBeyond && !isHomeRecover;
    },
    endDate: new Date('2024-12-13T00:00:00'),
    startDate: new Date('2024-09-17T00:00Z'),
    name: PromoName.TargetedOfferSixMonthsFree,
    footnote: q3FreeMonthsInsuranceFootnotes.q3FreeMonthsInsuranceFlashSale,
    appliesTo: [
      ProductName.STARTER,
      ProductName.ESSENTIALS,
      ProductName.COMPLETE,
    ],
    promoOptions: {
      type: 'FIRSTMONTHSFREE',
      duration: 6,
      codes: [
        ...generateSequentialPromocode(Promo.Q4SAVE, 150),
        Promo.Q424BEYOND,
      ],
    },
  },
  {
    order: 2,
    startDate: new Date('2024-01-09T00:00Z'),
    name: PromoName.Q1AbsDirectOffer,
    appliesTo: [ProductName.ABS],
    promoOptions: {
      type: 'PERCENTDISCOUNT',
      percentage: 50,
      codes: q1HalfPriceAbsPromoCodes,
    },
  },
  {
    order: 3,
    name: PromoName.AbsBeyond,
    startDate: new Date('2023-11-30T00:00:00'),
    endDate: new Date('2024-04-16T00:00:00'),
    appliesTo: [ProductName.ABS],
    footnote: beyondPromoFootnotes?.beyondPromo,
    promoOptions: {
      type: 'PERCENTDISCOUNT',
      percentage: 71.42,
      codes: [Promo.BEYOND],
    },
  },
  {
    order: 4,
    name: PromoName.EnergyRefixFreeABS,
    startDate: new Date('2024-08-28T00:00:00'),
    appliesTo: [ProductName.ABS],
    footnote: energyRefixFreeAbsFootnote.energyRefixFreeAbs,
    promoOptions: {
      type: 'FREE',
      codes: energyRefixFreeAbsPromoCodes,
    },
  },
  {
    order: 5,
    name: PromoName.BeyondFreeAbs,
    startDate: new Date('2024-04-16T00:00:00'),
    appliesTo: [ProductName.ABS],
    footnote: beyondPromoFootnotes?.beyondFreeAbs,
    promoOptions: {
      type: 'FREE',
      codes: q2HalfPriceAbsPromoCodes,
    },
  },
  {
    order: 5,
    name: PromoName.Beyond12MonthsHalfPrice,
    validate: () => {
      const store = storeService.load();
      return store.isBeyond === true;
    },
    startDate: new Date('2024-05-14T00:00:00'),
    endDate: new Date('2024-09-23T00:00:00'),
    appliesTo: [
      ProductName.STARTER,
      ProductName.ESSENTIALS,
      ProductName.COMPLETE,
    ],
    footnote: beyondPromoFootnotes?.beyond12MonthsHalfPrice,
    promoOptions: {
      type: 'FIRSTMONTHSDISCOUNT',
      duration: 12,
      discountPercentage: 50,
      codes: [Promo.BEYOND12HP],
    },
  },
];

const allPromos: PromoConfig[] = {
  ...indirectPromos,
  ...directPromos,
};

export const getIndirectPromos = () => [...indirectPromos];
export const getDirectPromos = () => [...directPromos];
export const getAllPromos = () => [...allPromos];
